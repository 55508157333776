body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-pdf__Page__canvas {
  /* position: absolute !important; */
   top: 0% !important;
   left: 0% !important;
   transform: translate(-0%, 0%) !important;
}

.react-pdf__Page__textContent {
   /* position: absolute !important; */
   top: 0% !important; 
   left: 0% !important;
  transform: translate(-0%, 0%) !important;
}
